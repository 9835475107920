<template>
  <div class="single-book-view">
    <b-row align-h="between">
      <b-col cols="12" sm="12" lg="8">
        <ebp-card :loading="isLoading">
          <b-row align-h="between">
            <b-col cols="6" sm="6" lg="8">
              <h5 class="title">Title Details</h5>
            </b-col>
            <b-col cols="6" sm="6" lg="4" class="text-right">
              <b-button variant="primary" @click="editBook">Edit Book</b-button>
              <b-button
                variant="danger"
                class="ml-1"
                @click="unpublishBook"
                v-if="isPublished"
                >Unpublish</b-button
              >
            </b-col>
          </b-row>

          <b-row align-h="between">
            <b-col
              cols="3"
              sm="6"
              lg="3"
              class="flex-row justify-content-center d-flex"
              v-if="this.book.thumbnail_url"
            >
              <img :src="this.book.thumbnail_url" class="bookImage" />
            </b-col>
            <b-col cols="9" sm="6" lg="9">
              <div class="bookMetadata">
                <div class="bookMetadata__item">
                  <div class="label">Title:</div>
                  <div class="value">{{ this.book.title }}</div>
                </div>
                <div class="bookMetadata__item">
                  <div class="label">Author:</div>
                  <div class="value">
                    {{
                      this.book.authors
                        ? this.formatAuthors(this.book.authors)
                        : "N/A"
                    }}
                  </div>
                </div>
                <div class="bookMetadata__item">
                  <div class="label">ISBN:</div>
                  <div class="value">{{ this.relevantISBN }}</div>
                </div>
                <div class="bookMetadata__item">
                  <div class="label">Type:</div>
                  <div class="value">
                    {{
                      this.book.book_type
                        ? this.book.book_type.toUpperCase()
                        : ""
                    }}
                  </div>
                </div>

                <div class="bookMetadata__item">
                  <div class="label">Selling on:</div>
                  <div class="value">
                    {{
                      this.book.sales_channels &&
                      this.book.sales_channels.length > 0
                        ? this.book.sales_channels
                            .map(function(el) {
                              return el.name;
                            })
                            .join(", ")
                        : "Not Available"
                    }}
                  </div>
                </div>

                <div class="bookMetadata__item">
                  <div class="label">Status:</div>
                  <div class="value">
                    <b-badge
                      :variant="statusVariant(this.book.status)"
                      class="mr-1"
                      >{{ $t(this.book.status) }}
                    </b-badge>
                  </div>
                </div>

                <div class="bookMetadata__item">
                  <div class="label">Notes:</div>
                  <div class="value">
                    {{ this.book.notes ? this.book.notes : "None" }}
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </ebp-card>

        <ebp-card>
          <h5 class="title">Monthly Sales</h5>
          <b-row align-h="between" class="date-filter">
            <b-col cols="12" sm="4" lg="3">
              <span>Select Date range</span>
            </b-col>
            <b-col cols="12" sm="8" lg="9">
              <DatePicker
                type="month"
                v-model="date_range"
                :clearable="false"
                range
                :placeholder="$t('date-range')"
                valueType="format"
                format="MMM-YYYY"
              />
            </b-col>
          </b-row>

          <div style="display: block; min-width: 300px;" v-if="!isLoading">
            <apexchart
              v-if="!isLoading"
              width="100%"
              type="bar"
              :series="series"
              :options="chartOptions"
              height="300px"
            />
          </div>
        </ebp-card>
      </b-col>

      <b-col cols="12" sm="12" lg="4">
        <ebp-card v-if="$isAdmin">
          <h5 class="title">Assignment</h5>
          <div class="bookMetadata">
            <div class="bookMetadata__item">
              <div class="label">Assigned to:</div>
              <div class="value">
                {{
                  this.book.user
                    ? this.book.user.name + " ( " + this.book.user.email + " )"
                    : "Not available"
                }}
              </div>
            </div>
          </div>
        </ebp-card>

        <ebp-card :loading="isLoadingFigures">
          <h5 class="title">Sales & Royalty Summary</h5>
          <div class="saleSummary">
            <div class="saleSummary__row head">
              <div class="label"></div>
              <div class="label">Sales</div>
              <div class="label">Royalties Earned (but not yet paid)</div>
              <div class="label">Royalties Paid to you</div>
            </div>
            <div
              class="saleSummary__row"
              v-for="(summary, index) in this.summaryRows"
              :key="summary.period"
              :index="index"
            >
              <div
                :class="
                  index === summaryRows.length - 1 ? 'label bold' : 'label '
                "
              >
                {{ summary.period }}
              </div>
              <div
                :class="
                  index === summaryRows.length - 1
                    ? 'label right bold'
                    : 'label right '
                "
              >
                {{ formatNumber(summary.totalSales) }}
              </div>
              <div
                :class="
                  index === summaryRows.length - 1
                    ? 'label right bold'
                    : 'label right '
                "
              >
                {{ formatCurrency(summary.royaltiesOutstanding, "GBP", 0) }}
              </div>
              <div
                :class="
                  index === summaryRows.length - 1
                    ? 'label right bold'
                    : 'label right '
                "
              >
                {{ formatCurrency(summary.royaltiesPaid, "GBP", 0) }}
              </div>
            </div>
          </div>
        </ebp-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import bookHelper from "@/helpers/book";
import { differenceInMonths, format, parse, subMonths } from "date-fns";
import DatePicker from "../core/DatePicker";
import { forEach } from "lodash-es";
import addMonths from "date-fns/addMonths";

const formatString = "MMM-yyyy";

export default {
  name: "client-view-book-summary",
  props: {
    options: Object
  },
  components: {
    DatePicker
  },
  created() {
    this.getBaseData();
    this.getSalesSummary();
  },
  data() {
    return {
      isPublished: false,
      isLoading: true,
      isLoadingFigures: true,
      book: {},
      date_range: [
        format(subMonths(Date.now(), 12), formatString),
        format(Date.now(), formatString)
      ],
      chartDates: [],
      summaryRows: [],
      groupedData: []
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    relevantISBN() {
      if (this.book.epub_isbn) {
        return this.book.epub_isbn;
      }

      if (this.book.print_isbn) {
        return this.book.print_isbn;
      }

      if (this.book.asin) {
        return this.book.asin + " (ASIN)";
      }

      return "N/A";
    },
    chartDateRange() {
      const startDate = parse(this.date_range[0], "MMM-yyyy", new Date());
      const endDate = parse(this.date_range[1], "MMM-yyyy", new Date());
      const diffInMonths = Math.abs(differenceInMonths(startDate, endDate)) + 1;

      return new Array(diffInMonths)
        .fill(0)
        .map((_, i) => format(addMonths(startDate, i), "MMM-yyyy"));
    },
    series() {
      let series = {};
      forEach(this.groupedData, row => {
        Object.keys(row.sale_data).forEach(key => {
          if (series[key] === undefined) {
            series[key] = {};
          }

          series[key][row.period] = row.sale_data[key];
        });
      });

      let data = [];

      Object.keys(series).forEach(channelName => {
        let seriesData = new Array(
          Object.values(this.chartDateRange).length
        ).fill(0);

        this.chartDateRange.forEach((month, index) => {
          const baseArrayMonth = parse(month, "MMM-yyyy", new Date());
          const formattedBaseArrayMonth = format(baseArrayMonth, "yyyy-MM");

          Object.keys(series[channelName]).forEach(channelMonthName => {
            if (channelMonthName === formattedBaseArrayMonth) {
              seriesData[index] = series[channelName][channelMonthName];
            }
          });
        });

        data.push({
          name: channelName,
          data: seriesData
        });
      });

      return data;
    },
    chartOptions() {
      const chartOptions = {
        colors: this.$colors,
        xaxis: {
          categories: this.chartDateRange
        },
        yaxis: {
          title: {
            text: this.$t("sales-downloads-text"),
            style: {
              fontSize: "14px",
              fontFamily: "SF UI Text"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          },
          style: "hollow"
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            }
          ]
        },
        noData: {
          text: "No sales reported for this period",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined
          }
        }
      };
      return chartOptions;
    }
  },
  methods: {
    async getBaseData() {
      if (!this.id) {
        this.$router.push("admin/books");
      }

      this.ebpLoader(true);

      try {
        const res = await this.$store.dispatch("books/show", this.id);
        this.book = bookHelper.convertFromAPIFormat(res.data);

        await this.getSalesData();

        if (this.book.status === "live") {
          this.isPublished = true;
        }

        this.isLoading = false;
      } catch (err) {
        console.error(err);
      }

      this.ebpLoader(false);
    },

    async getSalesData() {
      try {
        let filters = {
          period: this.date_range
            .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
            .join(",")
        };

        if (this.book) {
          filters.book_id = this.book.id;
        }

        const res = await this.$store.dispatch(
          "reports/titleSaleSummary",
          filters
        );
        this.groupedData = res.data;
      } catch (err) {
        console.log("Single book summary error: " + err);
      }
    },

    async getSalesSummary() {
      try {
        const res = await this.$store.dispatch(
          "reports/titleSaleTotalFigures",
          this.id
        );

        Object.keys(res.data).forEach(summary => {
          this.summaryRows.push(res.data[summary]);
        });

        this.isLoadingFigures = false;
      } catch (err) {
        console.log("Error fetching sales summary:" + err);
      }
    },

    formatAuthors: function(authors) {
      let string = "";

      try {
        authors.forEach(author => {
          string += author.first_name + " " + author.last_name + ", ";
        });

        string = string.substr(0, string.length - 2);
      } catch (error) {
        console.log(error.toString());
        string = "N/A";
      }

      return string;
    },
    editBook() {
      this.$nextTick(() => {
        const id = this.book.id;

        this.$router.push({
          name: this.$isAdmin ? "AdminEditBook" : "EditBook",
          params: { id }
        });
      });
    },
    async unpublishBook() {
      const decision = window.confirm(
        "Are you sure you want to un-publish this book?"
      );

      if (!decision) {
        return false;
      }

      this.isLoading = true;
      await this.$store.dispatch("books/unpublish", this.id);
      window.location.reload();
    }
  },
  watch: {
    date_range() {
      this.getSalesData();
    }
  }
};
</script>

<style lang="scss">
.bookMetadata {
  &__item {
    display: flex;

    .label {
      font-weight: bold;
      min-width: 140px;
      padding: 5px 10px;
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
    }

    .value {
      flex-grow: 1;
      padding: 5px;
    }
  }
}

.date-filter {
  padding: 10px;
  background: #f0faff;
}

.saleSummary {
  &__row {
    display: flex;
    justify-content: space-evenly;

    &.head {
      font-weight: bold;
      font-size: 12px;
      background: #f0faff;
      display: flex;
      align-items: center;

      .label {
        border: 0 !important;
      }
    }

    .label {
      width: 150px;
      border: solid 1px #eee;
      padding: 5px 8px;

      &.bold {
        background: #f7f7f7;
        font-weight: bold;
      }

      &.right {
        text-align: right;
      }
    }
  }
}

.bookImage {
  width: 150px;
}
</style>
